<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="selected.length == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!statusAtendido"
              v-bind="attrs"
              v-on="on"
              @click="showPosConsulta"
              color="primary"
            >
              Orientação Pós-consulta
            </v-btn>
          </template>
          <span>Orientação Pós-consulta</span>
        </v-tooltip>
      </v-card-title>

      <v-card-subtitle>
        <v-row dense class="pt-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                v-bind="attrs"
                v-on="on"
                text
                min-width="48"
                class="ml-0 mb-1 px-2 d-inline-flex"
                @click="atualizarListaRefresh"
              >
                <v-icon color="primary">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Atualizar Lista</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn-toggle v-model="refreshList" color="primary" dense>
                <v-btn
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="ml-1 mb-1 mr-1"
                  :value="true"
                >
                  <v-icon :color="refreshList ? 'primary' : 'grey'"
                    >mdi-history</v-icon
                  >
                </v-btn>
              </v-btn-toggle>
            </template>
            <span
              >{{ refreshList ? "Desativar" : "Ativar" }} atualização automática
              ( Atualiza a lista a cada 60 segundos )</span
            >
          </v-tooltip>
          <v-tooltip bottom close-delay="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn-toggle
                @change="changeStatusFilterBottom"
                v-model="statusFilterBottom"
                class="d-inline-block"
                color="primary"
                multiple
                dense
              >
                <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(0) > -1 ? '' : 'green white--text'">
                  <span>AGENDADO</span>
                </v-btn>
                <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(1) > -1 ? '' : 'primary white--text'">
                  <span>ATENDIDO</span>
                </v-btn>
                <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(2) > -1 ? '' : 'red white--text'">
                  <span>CANCELADO</span>
                </v-btn>
              </v-btn-toggle>
            </template>
            <span>Filtrar situações</span>
          </v-tooltip>
        </v-row>
        <v-row>

          <v-chip v-for="(f, i) in favoritos" :key="i"
            v-model="favoritos"
            class="ml-2"
            :class="f.active"
            @click="executarFavorito(f)"
            style="min-width: 150px"
            small
            
          >
            <v-avatar left>
              <v-icon>mdi-star-circle</v-icon>
            </v-avatar>
            {{ f.nome }}
          </v-chip>

        </v-row>
      </v-card-subtitle>

      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="items"
          v-model="selected"
          selectable-key="id"
          show-select
          single-select
          mobile-breakpoint="900"
          id="atendimento-table-item"
          :item-class="getRowClassItem"
          :search="searche"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
        >
          <template v-slot:[`item.historiaClinicaIcon`]="{ item }">
            <v-row dense no-gutters>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-show="!item.isPreenchimentoRepostasHistoriaClinicaJaIniciado" v-bind="attrs" v-on="on" @click="visualizarHistoriaClinica(item)" class="icon-historia-clinica">
                    mdi-clipboard-text-outline
                  </v-icon>
                  <v-icon v-show="item.isPreenchimentoRepostasHistoriaClinicaJaIniciado" v-bind="attrs" v-on="on" @click="visualizarHistoriaClinica(item)" class="icon-historia-clinica">
                    mdi-clipboard-edit-outline
                  </v-icon>
                </template>
                <span>Abrir Historia Clinica</span>
              </v-tooltip>
            </v-row>
          </template>

          <template v-slot:[`item.registroPreAtendimento.agendamento.paciente.nome`]="{ item }">
            <v-row dense class="pt-2 pb-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" link @click="editar(item)" :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'">
                    {{ $_.startCase(item.registroPreAtendimento.agendamento.paciente.nome.toLowerCase()) }}
                  </a>
                </template>
                <span>Abrir atendimento</span>
              </v-tooltip>
            </v-row>
            <v-row dense class="mb-2" v-if="item.relatorioMedicoId != null">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-chip v-on="on" class="ellipsis spin-anexos" outlined @click="visualizarImpressao(item.id)">
                    <template v-if="!gerandoPdf || gerandoPdfItemId != item.id">
                      <v-img
                        class="brf"
                        aria-hidden="true"
                        src="//ssl.gstatic.com/docs/doclist/images/mediatype/icon_3_pdf_x16.png"
                        srcset="//ssl.gstatic.com/docs/doclist/images/mediatype/icon_3_pdf_x32.png 2x"
                      >
                      </v-img>
                    </template>
                    <template v-else>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                    <span class="brg">{{ item.titulo }}.pdf</span>
                  </v-chip>
                </template>
                <span>{{ item.titulo }}.pdf</span>
              </v-tooltip>
            </v-row>
          </template>

          <template v-slot:[`item.registroPreAtendimento.agendamento.dataConsulta`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-on="!isDateToday(item.registroPreAtendimento.agendamento.dataConsulta) ? on : false" v-bind="attrs">
                  {{ formatDataConsulta(`${item.registroPreAtendimento.agendamento.dataConsulta}Z`) }}
                </span>
              </template>
              <span> {{formatDataConsultaDisplay(item.registroPreAtendimento.agendamento.dataConsulta)}} </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.statusAtendimento`]="{ item }">
            <v-chip dark small label style="width: 100px" class="mr-2 justify-center" :color="statusColor(`${item.statusAtendimento.id}`)">
              <span>{{ item.statusAtendimento.descricao }}</span>
            </v-chip>
          </template>
          
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" 
                  light
                  x-small 
                  class="text-capitalize"
                  id="item-actions-botton"
                  v-if="(possuiPermissaoAtendente || possuiPermissaoAdmin || possuiPermissaoAuxiliarAdmin) && !item.atendimentoAtendidoExpirado"
                >Ações</v-btn>
              </template>
              <v-list light rounded outlined elevation="2">
                <v-list-item dense class="px-0">
                  <v-list-item-action class="ma-0" style="align-items: flex-start">
                    <v-btn small plain left class="text-capitalize" @click="linkTeleconsulta(item)">Links</v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense class="px-0" v-if="item.linkFormulario">
                  <v-list-item-action class="ma-0" style="align-items: flex-start">
                    <v-btn small plain left class="text-capitalize" @click="visualizarHistoriaClinica(item)">História Clinica</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

        </v-data-table>

        <v-row>
          <v-col cols="12" md="4">
            <v-dialog
              v-model="registroPosConsultaComponentOpts.dialogPosConsulta"
              persistent
            >
              <RegistroPosConsultaComponent
                :isModal="true"
                :idParameter="atendimentoSelecionado.registroPosConsulta.id"
                :nomeTipoConsulta="
                  atendimentoSelecionado.registroPreAtendimento.agendamento
                    .tipoConsulta.nome
                "
                :inativo="
                  (atendimentoSelecionado.registroPreAtendimento.agendamento
                    .unidade.situacao === false ||
                    atendimentoSelecionado.registroPreAtendimento.agendamento
                      .unidade.empresa.situacao === false ||
                    isAdminAuxiliar()) &&
                  !isAdminMaster()
                "
                v-if="registroPosConsultaComponentOpts.showPosConsultaForm"
                @updated="posConsultaUpdated"
                @saved="posConsultaSaved"
                @error="posConsultaError"
                @exit="posConsultaExit"
              />
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-dialog
              v-model="linkTeleConsultaComponent.dialogLinkTeleConsulta"
              persistent
            >
              <v-card>
                <v-card-title>MEZZOW TELECONSULTA</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-data-table
                        v-bind:headers="headersPaciente"
                        v-bind:items="pacientesLink"
                        hide-default-footer
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4">
                      ID MEZZOW TELECONSULTA:
                      {{ atendimentoSelecionado.idTeleConsulta }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="1"> Link Paciente: </v-col>
                    <v-col>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            right
                            outlined
                            class="mr-2"
                            ripple
                            color="primary"
                            :href="
                              atendimentoSelecionado.linkTeleConsultaPaciente
                            "
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mr-2"
                              >mdi-link-box-variant-outline</v-icon
                            >
                            Clique Aqui
                          </v-btn>
                        </template>
                        <span>Abrir Link do Paciente</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      cols="12"
                      md="1"
                      v-if="atendimentoSelecionado.linkFormulario"
                    >
                      Link Formulario:
                    </v-col>
                    <v-col v-if="atendimentoSelecionado.linkFormulario">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            right
                            outlined
                            class="mr-2"
                            ripple
                            color="primary"
                            :href="atendimentoSelecionado.linkFormulario"
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mr-2"
                              >mdi-link-box-variant-outline</v-icon
                            >
                            Clique Aqui
                          </v-btn>
                        </template>
                        <span>Abrir Link do Formulario</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="1"> Link Médico </v-col>
                    <v-col>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            right
                            outlined
                            class="mr-2"
                            ripple
                            color="primary"
                            :href="
                              atendimentoSelecionado.linkTeleConsultaMedico
                            "
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mr-2"
                              >mdi-link-box-variant-outline</v-icon
                            >
                            Clique Aqui
                          </v-btn>
                        </template>
                        <span>Abrir Link do Médico</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    right
                    outlined
                    class="mr-2"
                    color="primary"
                    @click.native="linkTeleConsultaComponent.dialogLinkTeleConsulta = false"
                    >Fechar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" persistent max-width="450">
        <v-card>
          <v-card-title>{{ confirm.title }}</v-card-title>
          <v-card-text>{{ confirm.contentHtml }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="red--text darken-1"
              @click.native="dialog = false"
              >{{ confirm.cancel }}</v-btn
            >
            <v-btn
              text
              class="green--text darken-1"
              @click.native="excluir()"
              >{{ confirm.ok }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <alerta></alerta>

      <v-dialog
        v-model="dialogFiltroPesquisa"
        top
        content-class="my-custom-dialog"
        max-width="80%"
        hide-overlay
        transition="fab-transition"
        @click:outside="fecharDialogFiltroPesquisa"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros da pesquisa</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="fecharDialogFiltroPesquisa">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container fluid>
            <v-row>
              <v-col md="6" cols="12">
                <v-autocomplete
                  rounded
                  filled
                  v-model="filtroPesquisa.periodo"
                  @change="periodoDeTempoChange"
                  :items="periodosDeTempo"
                  label="Periodo de Tempo"
                  item-text="nome"
                  item-value="id"
                  return-object
                  chips
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
              <v-col md="3" cols="12">
                <v-menu
                  ref="show_start_date"
                  :close-on-content-click="false"
                  v-model="show_start_date"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="start_date"
                      @change="$emit('changeFavorite')"
                      label="Data Inicial"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_dateFormatada"
                    @input="show_start_date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="3" cols="12">
                <v-menu
                  ref="show_end_date"
                  :close-on-content-click="false"
                  v-model="show_end_date"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="end_date"
                      @change="$emit('changeFavorite')"
                      label="Data Final"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_dateFormatada"
                    @input="show_end_date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12">
                <v-autocomplete
                  rounded
                  filled
                  v-model="filtroPesquisa.grupoEmpresa"
                  :items="grupoEmpresas"
                  label="Grupo Empresa"
                  @change="$emit('changeFavorite')"
                  item-text="nome"
                  item-value="id"
                  return-object
                  chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>

              <v-col md="6" cols="12">
                <v-autocomplete
                  rounded
                  filled
                  v-model="filtroPesquisa.empresa"
                  :items="empresas"
                  label="Empresa"
                  @change="$emit('changeFavorite')"
                  item-text="nome"
                  item-value="id"
                  return-object
                  chips
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12">
                <v-autocomplete
                  rounded
                  filled
                  :items="unidades"
                  label="Unidade"
                  @change="$emit('changeFavorite')"
                  item-text="nome"
                  item-value="id"
                  return-object
                  v-model="filtroPesquisa.unidade"
                  chips
                  multiple
                  deletable-chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      close
                      small
                      @click:close="
                        filtroPesquisa.unidade.splice(
                          filtroPesquisa.unidade.indexOf(item),
                          1
                        )
                      "
                    >
                      <span>{{ item.nome }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-autocomplete
                  v-model="filtroPesquisa.tipoOrientacaoPosConsulta"
                  :items="tiposOrientacaoPosConsultas"
                  label="Tipo Orientação Pos"
                  @change="$emit('changeFavorite')"
                  item-text="titulo"
                  item-value="id"
                  return-object
                  multiple
                  rounded
                  chips
                  filled
                  deletable-chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      close
                      small
                      @click:close="
                        filtroPesquisa.tipoOrientacaoPosConsulta.splice(
                          filtroPesquisa.tipoOrientacaoPosConsulta.indexOf(
                            item
                          ),
                          1
                        )
                      "
                    >
                      <span>{{ item.titulo }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-autocomplete
                  v-model="filtroPesquisa.tipoConsulta"
                  :items="tiposConsultas"
                  label="Tipo Consulta"
                  @change="$emit('changeFavorite')"
                  item-text="nome"
                  item-value="id"
                  return-object
                  multiple
                  rounded
                  chips
                  filled
                  deletable-chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      close
                      small
                      @click:close="
                        filtroPesquisa.tipoConsulta.splice(
                          filtroPesquisa.tipoConsulta.indexOf(item),
                          1
                        )
                      "
                    >
                      <span>{{ item.nome }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col md="6" cols="12">
                <v-text-field
                  v-model="filtroPesquisa.nomePaciente"
                  append-icon="search"
                  @change="$emit('changeFavorite')"
                  label="Nome Paciente"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="6"
                cols="12"
                v-if="possuiPermissaoAdmin || !possuiPermissaoMedicoAtendente"
              >
                <v-autocomplete
                  v-model="filtroPesquisa.medicoResponsavel"
                  :items="medicosResponsaveis"
                  label="Médico Responsável"
                  @change="$emit('changeFavorite')"
                  item-text="nomeExibicao"
                  item-value="id"
                  return-object
                  multiple
                  rounded
                  chips
                  filled
                  deletable-chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      close
                      small
                      @click:close="
                        filtroPesquisa.medicoResponsavel.splice(
                          filtroPesquisa.medicoResponsavel.indexOf(item),
                          1
                        )
                      "
                    >
                      <span>{{ item.nomeExibicao }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col md="6" cols="12">
                <v-autocomplete
                  v-model="filtroPesquisa.status"
                  :items="statuss"
                  label="Status"
                  @change="$emit('changeFavorite')"
                  item-text="nome"
                  item-value="id"
                  return-object
                  multiple
                  rounded
                  chips
                  filled
                  deletable-chips
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      close
                      small
                      @click:close="
                        filtroPesquisa.status.splice(
                          filtroPesquisa.status.indexOf(item),
                          1
                        )
                      "
                    >
                      <span>{{ item.nome }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-chip
              v-if="isFavorited"
              class="ma-2 pr-4 elevation-2"
              color="teal"
              text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-star-circle</v-icon>
              </v-avatar>
              {{ favoritoAtual.nome }}
            </v-chip>
            <v-btn icon>
              <v-tooltip bottom v-if="!isFavorited">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    dark
                    @click="openDialogFavoritarFiltroPesquisa"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-star-plus-outline</v-icon
                  >
                </template>
                <span>Adicionar este filtro aos Favoritos</span>
              </v-tooltip>

              <v-icon
                x-large
                dark
                v-if="
                  isFavorited &&
                  (favoritoMouseEvent === null || favoritoMouseEvent === -1)
                "
                @click="isFavorited = !isFavorited"
                @mouseover="favoritoMouseEvent=0"
                @mouseleave="favoritoMouseEvent=-1"
              >
                mdi-star-check
              </v-icon>

              <v-tooltip bottom v-if="isFavorited && favoritoMouseEvent === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    dark
                    @click="removerFavoritosFiltroPesquisa"
                    v-bind="attrs"
                    v-on="on"
                    @mouseleave="favoritoMouseEvent=-1"
                    >mdi-star-remove</v-icon
                  >
                </template>
                <span>Remover filtro dos Favoritos</span>
              </v-tooltip>
            </v-btn>
            <v-btn color="secondary" @click="limpar"> Limpar </v-btn>
            <v-btn color="primary" @click="buscar"> buscar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFavorito" persistent  max-width="450">
        <v-card>
            <v-card-title>Informe o nome do Favorito</v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="favoritoForm.nome"
                    label="Nome"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="red--text darken-1" @click.native="dialogFavorito = false">Cancelar</v-btn>
            <v-btn text class="green--text darken-1" @click.native="favoritarFiltroPesquisa">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog 
        v-model="dialogShowLinkHistoriaClinica"
        scrollable
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card flat>
          <v-toolbar dense dark color="primary" max-height="50">
            <v-btn icon @click.native="closeDialogVisualizarHistoriaClinica()" dark>
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>Formulário da História Clincia</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <iframe v-show="dialogShowLinkHistoriaClinica" id="ifrm" :src="atendimentoSelecionadoHistoriaClinica.linkFormulario" width="100%" height="100%" style="overflow: hidden"></iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import RegistroPosConsultaComponent from "@/views//atendimento/RegistroPosConsultaCreateEdit.vue";
import { mapActions, mapGetters } from "vuex"; 
import { bus } from "../../main";
import moment from "moment";
import {
  possuiPermissao,
  getUserPreferences,
  getPerfilId,
  clearTemporaryUserPreferences,
  isAdminMaster,
  isAdminAuxiliar,
} from "../../seguranca";
import Ripple from "vuetify/lib/directives/ripple";
export default {
  directives: {
    Ripple,
  },
  name: "atendimento-list",
  data() {
    return {
      dialogShowLinkHistoriaClinica: false,
      dialogFavorito:false,
      favoritoForm:{nome:''},
      colorsFavoritos: ['green', 'purple', 'orange', 'indigo', 'red'],
      favoritoMouseEvent: null,
      isFavorited: false,
      favoritoAtual: null,
      favoritos: [],
      statusFilterBottom: [],
      refreshList: undefined,
      refreshInterval: null,
      gerandoPdf: false,
      gerandoPdfItemId: null,
      periodosDeTempo: [
        { nome: "PRÓXIMOS 30 DIAS", id: "PROXIMOS_30_DIAS" },
        { nome: "PRÓXIMOS 7 DIAS", id: "PROXIMOS_07_DIAS" },
        { nome: "AMANHÃ", id: "AMANHA" },
        { nome: "HOJE", id: "HOJE" },
        { nome: "ONTEM", id: "ONTEM" },
        { nome: "ÚLTIMOS 3 DIAS", id: "ULTIMOS_03_DIAS" },
        { nome: "ÚLTIMOS 7 DIAS", id: "ULTIMOS_07_DIAS" },
        { nome: "ÚLTIMOS 30 DIAS", id: "ULTIMOS_30_DIAS" },
      ],
      atendimentoSelecionado: {
        registroPosConsulta: {},
      },
      atendimentoSelecionadoHistoriaClinica: {
        registroPosConsulta: {},
        linkFormulario:"https://www.mezzow.com"
      },
      selectedCheckBox: [],
      registroPosConsultaComponentOpts: {
        showPosConsultaForm: false,
        dialogPosConsulta: false,
        lastPosConsultaEvent: "",
        lastPosConsultaDados: {},
      },
      txtFilter: "",
      nomePacienteElemento: "",
      agendamentoSelecionado: {
        preAtendimento: {},
      },
      atendimentoFilterStorage: {
        start_date: null,
        end_date: null,
        grupoEmpresa: null,
        empresa: null,
        unidade: null,
        setor: null,
        medicoResponsavel: null,
        tipoConsulta: null,
        tipoOrientacaoPosConsulta: null,
        status: null,
        txtFilter: null,
      },
      registroPreAtendimentoComponentOpts: {
        showPreAtendimentoForm: false,
        dialogPreAtendimento: false,
        lastPreAtendimentoEvent: "",
        lastPreAtendimentoDados: {},
      },
      linkTeleConsultaComponent: {
        showLinkTeleConsulta: false,
        dialogLinkTeleConsulta: false,
      },
      filtroPesquisa: {
        periodo: {
          id: "HOJE",
        },
      },
      dialogFiltroPesquisa: false,
      notifications: false,
      sound: true,
      widgets: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      weekdays: [1, 2, 3, 4, 5 /*, 6, 0*/],
      focus: "",
      type: "week",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],

      nomePacienteForm: "",
      cpfPacienteForm: "",
      statusPacienteForm: "",
      unidadePacienteForm: "",
      empresas: [],
      setores: [],
      medicosResponsaveis: [],
      tiposConsultas: [],
      tiposOrientacaoPosConsultas: [],
      show_start_date: false,
      start_date: null,
      start_dateFormatada: null,
      show_end_date: false,
      end_date: null,
      end_dateFormatada: null,
      filterss: "",
      filters: {
        nome: "",
        cpf: "",
        unidade: "",
        status: "",
        start_date: null,
        end_date: null,
      },
      pagination: {
        sortBy: "id",
      },
      unidades: [],
      grupoEmpresas: [],
      pacientes: [],
      pacientesUnmodified: [],
      statuss: [
        { nome: "AGENDADO", id: "1" },
        { nome: "ATENDIDO", id: "2" },
        { nome: "CANCELADO", id: "3" },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["registroPreAtendimento.agendamento.paciente.nome"],
        sortDesc: [false],
      },
      camposFiltro: [
        { value: "nomeUnidade", text: "Unidade" },
        { value: "nomePaciente", text: "Paciente" },
        { value: "cpfPaciente", text: "Paciente" },
        { value: "dataConsulta", text: "Data" },
        { value: "statusAgendamento", text: "Grupo Empresa" },
      ],
      campoFiltro: "nome",
      mapFieldNamesForSorting: {
        permissaoForView: "permissaoForView",
        username: "username",
        "u.username": "u.username",
      },
      loading: true,
      loadingPaciente: true,
      items: [],
      itemsUnmodified: [],
      modalidades: [],
      errors: [],
      total: null,
      titulo: "Atendimentos",
      confirm: {
        title: "Atenção",
        contentHtml:
          "Tem certeza que deseja excluir o(s) usuário(s) selecionado(s).",
        ok: "Sim",
        cancel: "Não",
      },
      dialog: false,
      snackbar: false,
      tipoAlerta: "",
      timeoutAlerta: 6000,
      textAlerta: "",
      searche: "",
      selected: [],
      selectAll: "",
      headers: [
        {
          align: "center",
          value: "historiaClinicaIcon",
          sortable: false,
        },
        {
          text: "Nome Paciente",
          align: "left",
          sortable: true,
          value: "registroPreAtendimento.agendamento.paciente.nome",
        },
        {
          text: "Data Consulta",
          align: "left",
          sortable: true,
          value: "registroPreAtendimento.agendamento.dataConsulta",
        },
        {
          text: "Tipo Consulta",
          align: "left",
          sortable: true,
          value: "registroPreAtendimento.agendamento.tipoConsulta.nome",
        },
        {
          text: "Unidade",
          align: "left",
          sortable: true,
          value: "registroPreAtendimento.agendamento.unidade.nome",
        },
        {
          text: "Empresa",
          align: "left",
          sortable: false,
          value: "registroPreAtendimento.agendamento.unidade.empresa.nome",
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          value: "statusAtendimento",
        },
      ],
      pacientesLink: [],
      headersPaciente: [
        {
          text: "Paciente",
          align: "left",
          sortable: false,
          value: "paciente",
        },
        {
          text: "Idade",
          align: "left",
          sortable: false,
          value: "idade",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: false,
          value: "tipo",
        },
        {
          text: "Data",
          align: "left",
          sortable: false,
          value: "data",
        },
        {
          text: "Unidade",
          align: "left",
          sortable: false,
          value: "unidade",
        },
      ],
    };
  },
  components: {
    Alerta,
    RegistroPosConsultaComponent,
  },
  computed: {
    ...mapGetters([
      "atendimentoQuery",
      "atendimentoRefreshList",
      "atendimentoLastItemId"
    ]),
    filteredItems() {
      return this.items;
    },
    statusAtendido() {
      if (this.selected.length == 1) {
        return this.selected[0].statusAtendimento.descricao == "ATENDIDO"
          ? true
          : false;
      }
      return false;
    },
    possuiPermissaoAdmin() {
      return possuiPermissao(["ROLE_ADMIN", "ROLE_CLIENTE_ADMIN"]);
    },
    possuiPermissaoAuxiliarAdmin() {
      return possuiPermissao(["ROLE_AUXILIAR_ADMIN"]);
    },
    possuiPermissaoAtendente() {
      return possuiPermissao(["ROLE_ATENDENTE"]);
    },
    possuiPermissaoMedicoAtendente() {
      return possuiPermissao([
        "ROLE_MEDICO_RADIOLOGISTA",
        "ROLE_MEDICO_ATENDIMENTO",
      ]);
    },
  },
  watch: {
    "start_dateFormatada"() {
      if (this.start_dateFormatada)
        this.start_date = moment(this.start_dateFormatada, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      this.atendimentoFilterStorage.start_date = this.start_date;
    },
    "end_dateFormatada"() {
      if (this.end_dateFormatada)
        this.end_date = moment(this.end_dateFormatada, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      this.atendimentoFilterStorage.end_date = this.end_date;
    },
    search() {
      this.setAgendamentoQuery({ query: "" /*this.filters*/ });
    },
    options: {
      handler() {
        this.setFilterQuery();
      },
      deep: true,
    },
    refreshList(value) {
      if (value) {
        this.refreshInterval = setInterval(() => {
          this.atualizarListaRefresh();
        }, 60 * 1000);
      } else {
        clearInterval(this.refreshInterval);
      }
      // Altera o estado da variável atendimentoRefreshList (vuex)
      this.setAtendimentoRefreshList(value);
    },
  },
  methods: {
    ...mapActions([
      "setUltimaTela",
      "setAlerta",
      "setAtendimentoQuery",
      "setAtendimentoRefreshList",
      "setAtendimentoLastItemId"
    ]),
    closeDialogVisualizarHistoriaClinica() {
      this.dialogShowLinkHistoriaClinica = false;
      this.atendimentoSelecionadoHistoriaClinica = {linkFormulario:"https://www.mezzow.com"};
    },
    visualizarHistoriaClinica(atendimento) {
      this.atendimentoSelecionadoHistoriaClinica = Object.assign({},atendimento)

      // TODO: Inclusion of test data...
      this.atendimentoSelecionadoHistoriaClinica.linkFormulario = this.atendimentoSelecionadoHistoriaClinica.linkFormulario.replace(`34.72.196.80`, `localhost`)
      this.dialogShowLinkHistoriaClinica = true
    },

    changeFavorite(){
      this.isFavorited = false;
    },
    genColor (i) {
      return this.colorsFavoritos[i]
    },
    isDesrmarcarFavorito(favo){
      return favo.active === "ml-1 mb-1 pr-4 elevation-0";
    },
    desmascarFavoritos(){
      this.favoritos.forEach(lf =>{
          lf.active = "ml-1 mb-1 pr-4 elevation-2 teal white--text";
      });
    },
    getFavoritoSelecionado(){
      self.favoritos.forEach(lf =>{
          if (lf.active === "ml-1 mb-1 pr-4 elevation-0"){
            return lf;
          }
      });
      return null;
    },
    selecionarFavorito(favo){
      this.favoritos.forEach(lf =>{
          if (lf.id === favo.id){
              lf.active = "ml-1 mb-1 pr-4 elevation-0";
          }else if (lf.active === "ml-1 mb-1 pr-4 elevation-0"){
            lf.active = "ml-1 mb-1 pr-4 elevation-2 teal white--text";
          }

      });
    },
    executarFavorito(favo){
      var self = this;
      
      if (this.isDesrmarcarFavorito(favo)){
        
        this.desmascarFavoritos();
        this.isFavorited = false;
        this.limpar();
        window.sessionStorage.removeItem(this.$UserPreferencesinstanceName);
        this.search = this.atendimentoQuery;
        // Carrega a variável a partir do atributo de estado do vuex (computed)
        this.refreshList = this.atendimentoRefreshList;
        this.setTelaUserPreferences();
        this.setFilterQuery();
      
      }else{
        this.filtroPesquisa = Object.assign({}, JSON.parse(favo.valor) );
        this.favoritoAtual = Object.assign({}, favo);
        
        if (self.filtroPesquisa.periodo){
          this.setarDataCorrespondente(self.filtroPesquisa.periodo);
        }else{
          self.start_dateFormatada  = self.filtroPesquisa.start_dateFormatada;
          self.end_dateFormatada    = self.filtroPesquisa.end_dateFormatada;
        }
        this.start_date = moment(self.start_dateFormatada, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.end_date = moment(self.end_dateFormatada, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.buscar();
        this.isFavorited = true;
        this.selecionarFavorito(favo);
      }

      
    },
    changeStatusFilterBottom(value) {
      this.filtroPesquisa.status = value.map(
        (v) => this.statuss.filter((s) => s.id == v + 1)[0]
      );
      this.buscar();
    },
    updateStatusFilterBottom() {
      // Atualiza os botões statusFilterBottom
      if (this.filtroPesquisa.status) {
        this.statusFilterBottom = this.filtroPesquisa.status.map((s) => s.id - 1);
      }else{
        this.statusFilterBottom.splice(0,this.statusFilterBottom.length)
      }
    },
    removerFavoritosFiltroPesquisa() {
      var self = this;
      this.favoritoAtual.situacao = false;
      this.$http
        .put("/favorito", this.favoritoAtual)
        .then(() => {
          self.isFavorited = false;
          self.buscarFavoritos();
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro removido dos favoritos com sucesso!",
          });
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta:
              "Ocorreu um erro ao remover o filtro dos favoritos. Por favor, contate o Administrador!",
          });
        });
    },
    openDialogFavoritarFiltroPesquisa() {
      var self = this;
      self.dialogFavorito=true;
    },
    favoritarFiltroPesquisa() {
      var self = this;
      
      self.filtroPesquisa.start_dateFormatada = this.start_dateFormatada;
      self.filtroPesquisa.end_dateFormatada   = this.end_dateFormatada;

      var favorito = {
        "tipo": "MZW2_ATENDIMENTO_LIST",
        "nome": self.favoritoForm.nome,
        "valor": JSON.stringify(Object.assign({}, self.filtroPesquisa))
      }

      this.$http
        .post("/favorito", favorito)
        .then((response) => {
          self.dialogFavorito=false;
          self.isFavorited = true;
          self.favoritoAtual = Object.assign({}, response.data);
          self.buscarFavoritos();
          
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro favoritado com sucesso!",
          });
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta:
              "Ocorreu um erro ao favoritar o filtro. Por favor, contate o Administrador!",
          });
        });
    },
    isAdminMaster() {
      return isAdminMaster();
    },
    isAdminAuxiliar() {
      return isAdminAuxiliar();
    },
    periodoDeTempoChange(periodoChange){
      this.setarDataCorrespondente(periodoChange);
      this.$emit('changeFavorite')
    },
    setarDataCorrespondente(periodoChange) {
      if (!periodoChange) periodoChange = { id: "DEFAULT" };

      if (periodoChange.id === "PROXIMOS_30_DIAS") {
        this.start_dateFormatada = moment().format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(30, "days").format("YYYY-MM-DD");
      } else if (periodoChange.id === "PROXIMOS_07_DIAS") {
        this.start_dateFormatada = moment().format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(7, "days").format("YYYY-MM-DD");
      } else if (periodoChange.id === "HOJE") {
        this.start_dateFormatada = moment().format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "AMANHA") {
        this.start_dateFormatada = moment().add(1, "days").format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(1, "days").format("YYYY-MM-DD");
      } else if (periodoChange.id === "ONTEM") {
        this.start_dateFormatada = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (periodoChange.id === "ULTIMOS_03_DIAS") {
        this.start_dateFormatada = moment()
          .subtract(3, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "ULTIMOS_07_DIAS") {
        this.start_dateFormatada = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "ULTIMOS_30_DIAS") {
        this.start_dateFormatada = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "DEFAULT") {
        this.start_dateFormatada = moment()
          .subtract(10, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(10, "days").format("YYYY-MM-DD");
      }
    },
    visualizarImpressao(idAtendimento) {

      if (!this.gerandoPdf) {
        this.gerandoPdf = true;
        this.gerandoPdfItemId = idAtendimento;
        this.$http
          .get(`/atendimento/download/${idAtendimento}`, {
            responseType: "blob",
          })
          .then((response) => {
            this.gerandoPdf = false;
            this.gerandoPdfItemId = null;

            let blob = new Blob([response.data], { type: "application/pdf" }),
              url = this.$window.URL.createObjectURL(blob);
            this.$window.open(url);
          })
          .catch(() => {
            this.gerandoPdf = false;
            this.gerandoPdfItemId = null;
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao gerar o documento",
            });
          });
      }
    },
    showPosConsulta() {
      var index = Object.keys(this.selected)[0];
      this.atendimentoSelecionado = Object.assign({}, this.selected[index]);

      // Altera o estado da variável de controle do ultimo item acessado.
      this.setAtendimentoLastItemId(this.atendimentoSelecionado.id)

      if (!this.atendimentoSelecionado.registroPosConsulta) {
        this.atendimentoSelecionado.registroPosConsulta = {};
      }

      if (
        typeof this.atendimentoSelecionado.registroPosConsulta.id ===
        "undefined"
      ) {
        this.atendimentoSelecionado.registroPosConsulta.id = null;
      } else {
        this.atendimentoSelecionado.registroPosConsulta.id =
          this.atendimentoSelecionado.registroPosConsulta.id + "";
      }

      this.registroPosConsultaComponentOpts.showPosConsultaForm = true;
      this.registroPosConsultaComponentOpts.dialogPosConsulta = true;
    },
    updateAtendimentoPut() {
      var self = this;
      let atendimentoRequest = Object.assign({}, self.atendimentoSelecionado);

      if (atendimentoRequest.registroPreAtendimento != null) {
        atendimentoRequest.registroPreAtendimento = Object.assign({},
          atendimentoRequest.registroPreAtendimento
        );
      }

      if (atendimentoRequest.registroPosConsulta != null) {
        atendimentoRequest.registroPosConsulta = Object.assign(
          {},
          atendimentoRequest.registroPosConsulta
        );
      }

      this.$http
        .put("/atendimento", atendimentoRequest)
        .then(() => {
          self.items.forEach((ele) => {
            if (ele.id === self.selected[0].id) {
              Object.assign(ele, atendimentoRequest);
            }
          });
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Atendimento atualizado com sucesso",
          });
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao atualizar a Pos consulta no Atendimento",
          });
        });
    },
    hidePosConsulta() {
      this.registroPosConsultaComponentOpts.showPosConsultaForm = false;
      this.registroPosConsultaComponentOpts.dialogPosConsulta = false;
      this.atendimentoSelecionado = {};
    },
    posConsultaUpdated() {
      this.hidePosConsulta();
    },
    posConsultaSaved(objPosConsultaSaved) {
      this.atendimentoSelecionado.registroPosConsulta =
        objPosConsultaSaved.data;
      this.updateAtendimentoPut();
      this.hidePosConsulta();
    },
    posConsultaError() {
      this.hidePosConsulta();
    },
    posConsultaExit() {
      this.hidePosConsulta();
    },
    formatDataConsulta(data) {
      let dataConsulta = this.$moment(data, "YYYY-MM-DDTHH:mm:ss");
      let _now = this.$moment();

      let dataConsultaAux = dataConsulta.format("YYYY-MM-DD");
      let dateNowAux = _now.format("YYYY-MM-DD");

      if (dataConsultaAux == dateNowAux) {
        return dataConsulta.locale('pt-br').format("HH:mm");
      } else {
        return dataConsulta.locale('pt-br').format('DD MMM');
      }
    },
    formatDataConsultaDisplay (dataConsulta) {
      return moment(dataConsulta).locale("pt-br").format("HH:mm")
    },
    isDateToday (date) {
      return moment().format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY")
    },
    triggerRegistroPreAtendimentoComponent(p_registropreAtendimento, evento) {
      Object.assign(this.registroPreAtendimentoComponentOpts, {
        showPreAtendimentoForm: false,
        dialogPreAtendimento: false,
        lastPreAtendimentoEvent: evento,
        lastPreAtendimentoDados: p_registropreAtendimento,
      });

      if (p_registropreAtendimento) {
        var index = this.items.findIndex(
          (obj) => obj.id == p_registropreAtendimento.agendamento.id
        );

        this.items[index].statusAgendamento = this.makeStatusObj(
          p_registropreAtendimento.status
        );
      }
    },
    linhaClicada(val) {
      var self = this;

      self.agendamentoSelecionado = Object.assign({}, val);

      this.$http
        .get(`/registroPreAtendimento?agendamentoId=${val.id}`)
        .then((response) => {
          if (response.data.totalSize > 0) {
            self.agendamentoSelecionado.preAtendimento = Object.assign(
              {},
              { id: response.data.content[0].id }
            );
          } else {
            self.agendamentoSelecionado.preAtendimento = {};
          }

          self.registroPreAtendimentoComponentOpts.showPreAtendimentoForm = true;
          self.registroPreAtendimentoComponentOpts.dialogPreAtendimento = true;
        })
        .catch((e) => {
          Promise.reject(
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: !e.response
                ? "Connection Refused!"
                : e.response.data.message,
            })
          );
        });
    },
    getIdadeByDataAniversario() {
      var today = new Date();
      var birthDate = new Date(
        this.atendimentoSelecionado.registroPreAtendimento.agendamento.paciente.dataNascimento
      );
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    copyLinkFormularioToClipBoard(atendimento) {
      navigator.clipboard.writeText(atendimento.linkFormulario);
      this.setAlerta({
        tipoAlerta: "success",
        textAlerta: "Link do formulario copiado com sucesso!",
      });
    },
    linkTeleconsulta(atendimento) {
      var self = this;

      self.atendimentoSelecionado = Object.assign({}, atendimento);

      // Altera o estado da variável de controle do ultimo item acessado.
      this.setAtendimentoLastItemId(atendimento.id)

      if (
        self.atendimentoSelecionado.linkTeleConsultaPaciente != null ||
        self.atendimentoSelecionado.linkTeleConsultaMedico != null
      ) {

        self.pacientesLink = Object.assign(
          [{}],
          [
            {
              paciente:
                self.atendimentoSelecionado.registroPreAtendimento.agendamento
                  .paciente.nome,
              idade: self.getIdadeByDataAniversario(),

              tipo: self.atendimentoSelecionado.registroPreAtendimento
                .agendamento.tipoConsulta
                ? self.atendimentoSelecionado.registroPreAtendimento.agendamento
                    .tipoConsulta.nome
                : "",
              data: self.formatDataConsulta(
                `${self.atendimentoSelecionado.registroPreAtendimento.agendamento.dataConsulta}Z`
              ),
              unidade:
                self.atendimentoSelecionado.registroPreAtendimento.agendamento
                  .unidade.nome,
            },
          ]
        );

        self.linkTeleConsultaComponent.showLinkTeleConsulta = true;
        self.linkTeleConsultaComponent.dialogLinkTeleConsulta = true;
      } else {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Não foram encontrados links para teleconsulta!",
        });
      }
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: this.rndElement(this.colors),
          start: this.createStart,
          end: this.createStart,
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },

    customFilter(items, filters, filter, headers) {

      // Init the filter class.
      const cf = new this.$MultiFilters(items, filters, filter, headers);
      // Use regular function(),
      // arrow functions does not allow context binding.
      // Register the global standard filter.
      cf.registerFilter("search", function (searchWord, items) {
        if (searchWord.trim() === "") return items;
        return items.filter((item) => {
          return item.name.toLowerCase().includes(searchWord.toLowerCase());
        }, searchWord);
      });
      cf.registerFilter("nome", function (searchWord, items) {
        if (searchWord.trim() === "") return items;
        return items.filter((item) => {
          return item.name.toLowerCase().includes(searchWord.toLowerCase());
        }, searchWord);
      });
      cf.registerFilter("cpf", function (searchWord, items) {
        if (searchWord.trim() === "") return items;
        return items.filter((item) => {
          return item.name.toLowerCase().includes(searchWord.toLowerCase());
        }, searchWord);
      });
      // Use regular function(),
      // arrow functions does not allow context binding.
      // Register "added_by" filter.
      cf.registerFilter("unidade", function (added_by, items) {
        // If the filter has not been applied yet
        // just return all available items.
        if (added_by.trim() === "") return items;
        // Compare each item added_by and just return the matching ones.
        return items.filter((item) => {
          return item.added_by.toLowerCase() === added_by.toLowerCase();
        }, added_by);
      });
      // Use regular function(),
      // arrow functions does not allow context binding.
      // Register "added_by" filter.
      cf.registerFilter("status", function (added_by, items) {
        // If the filter has not been applied yet
        // just return all available items.
        if (added_by.trim() === "") return items;
        // Compare each item added_by and just return the matching ones.
        return items.filter((item) => {
          return item.added_by.toLowerCase() === added_by.toLowerCase();
        }, added_by);
      });
      // Use regular function(),
      // arrow functions does not allow context binding.
      // Register "start_date" filter.
      cf.registerFilter("start_date", function (start_date, items) {
        // If the filter has not been applied yet
        // just return all available items.
        if (start_date === null) return items;
        // Compare each item start_date and just return the matching ones.
        return items.filter((item) => {
          return item.birth_date >= start_date;
        }, start_date);
      });
      // Use regular function(),
      // arrow functions does not allow context binding.
      // Register "end_date" filter.
      cf.registerFilter("end_date", function (end_date, items) {
        // If the filter has not been applied yet
        // just return all available items.
        if (end_date === null) return items;
        // Compare each item end_date and just return the matching ones.
        return items.filter((item) => {
          return item.birth_date <= end_date;
        }, end_date);
      });
      // Its time to run all created filters.
      // Will be executed in the order thay were defined.
      return cf.runFilters();
    },

    excluir() {
      var self = this;
      Object.keys(this.selected).forEach((index) => {
        const selecionado = this.selected[index];

        this.$http
          .delete(`/agendamento/${selecionado.id}`)
          .then(() => {
            self.atualizarLista();
            self.setAlerta({
              tipoAlerta: "success",
              textAlerta: "Usuário excluído com sucesso",
            });
          })
          .catch((e) => {
            Promise.reject(
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: !e.response
                  ? "Connection Refused!"
                  : e.response.data.message,
              })
            );
          });

        setTimeout(function () {
          self.selected = [];
        }, 3000);

        this.dialog = false;
      });
    },
    novo() {
      this.$router.push({ name: "AgendamentoCreate" });
    },
    editar(v) {
      
      // Altera o estado da variável de controle do ultimo item acessado.
      this.setAtendimentoLastItemId(v.id)
      // Navega para a página de edição do atendimento.
      this.$router.push({ name: "AtendimentoEdit", params: { id: v.id } });
    },
    limparClickFilter(){
      this.limpar();
      this.buscar();
    },
    limpar() {
      this.show_start_date = false;
      this.start_date = null;
      this.start_dateFormatada = null;
      this.show_end_date = false;
      this.end_date = null;
      this.end_dateFormatada = null;
      this.filtroPesquisa = {};
      this.filtroPesquisa.status = [];
      this.atendimentoFilterStorage = {};
      bus.$emit("clickSearchFilter", "");
      window.sessionStorage.removeItem(`atendimentoFilterStorage_${this.$instanceId}`);
      this.desmascarFavoritos();
      this.isFavorited = false;
    },
    atualizarListaRefresh() {
      clearTemporaryUserPreferences(this.$options.name);
      this.pesquisar();
    },
    buscar() {
      clearTemporaryUserPreferences(this.$options.name);
      this.pesquisar();
    },
    pesquisar() {
      this.options.page = 1;

      let txtFilter = ``;

      if (this.start_date && this.start_date != null) {
        txtFilter += ` =dataInicial:${this.start_date}`;
        this.atendimentoFilterStorage.start_date = this.start_date;
      } else {
        this.atendimentoFilterStorage.start_date = null;
      }

      if (this.end_date && this.end_date != null) {
        txtFilter += ` =dataFinal:${this.end_date}`;
        this.atendimentoFilterStorage.end_date = this.end_date;
      } else {
        this.atendimentoFilterStorage.end_date = null;
      }

      if (this.filtroPesquisa.periodo) {
        this.atendimentoFilterStorage.periodo = this.filtroPesquisa.periodo;
      } else {
        this.atendimentoFilterStorage.periodo = null;
      }

      if (
        this.filtroPesquisa.grupoEmpresa &&
        this.filtroPesquisa.grupoEmpresa.id &&
        this.filtroPesquisa.grupoEmpresa.id > 0
      ) {
        txtFilter += ` =grupoEmpresa:"${this.filtroPesquisa.grupoEmpresa.nome}"`;
        this.atendimentoFilterStorage.grupoEmpresa =
          this.filtroPesquisa.grupoEmpresa;
      } else {
        this.atendimentoFilterStorage.grupoEmpresa = null;
      }

      if (
        this.filtroPesquisa.empresa &&
        this.filtroPesquisa.empresa.id &&
        this.filtroPesquisa.empresa.id > 0
      ) {
        txtFilter += ` =empresa:"${this.filtroPesquisa.empresa.nome}"`;
        this.atendimentoFilterStorage.empresa = this.filtroPesquisa.empresa;
      } else {
        this.atendimentoFilterStorage.empresa = null;
      }

      if (
        this.filtroPesquisa.unidade &&
        this.filtroPesquisa.unidade.length > 0
      ) {
        txtFilter += ` =unidade:"${this.filtroPesquisa.unidade
          .map(function (k) {
            return k.nome;
          })
          .join(",")}"`;
        this.atendimentoFilterStorage.unidade = this.filtroPesquisa.unidade;
      } else {
        this.atendimentoFilterStorage.unidade = null;
      }

      if (this.filtroPesquisa.setor && this.filtroPesquisa.setor.length > 0) {
        txtFilter += ` =setor:"${this.filtroPesquisa.setor
          .map(function (k) {
            return k.nome;
          })
          .join(",")}"`;
        this.atendimentoFilterStorage.setor = this.filtroPesquisa.setor;
      } else {
        this.atendimentoFilterStorage.setor = null;
      }

      if (
        this.filtroPesquisa.medicoResponsavel &&
        this.filtroPesquisa.medicoResponsavel.length > 0
      ) {
        txtFilter += ` =medico:"${this.filtroPesquisa.medicoResponsavel
          .map(function (k) {
            return k.nomeExibicao;
          })
          .join(",")}"`;
        this.atendimentoFilterStorage.medicoResponsavel =
          this.filtroPesquisa.medicoResponsavel;
      } else {
        this.atendimentoFilterStorage.medicoResponsavel = null;
      }

      if (
        this.filtroPesquisa.tipoConsulta &&
        this.filtroPesquisa.tipoConsulta.length > 0
      ) {
        txtFilter += ` =tipoConsulta:"${this.filtroPesquisa.tipoConsulta
          .map(function (k) {
            return k.nome;
          })
          .join(",")}"`;
        this.atendimentoFilterStorage.tipoConsulta =
          this.filtroPesquisa.tipoConsulta;
      } else {
        this.atendimentoFilterStorage.tipoConsulta = null;
      }

      if (
        this.filtroPesquisa.tipoOrientacaoPosConsulta &&
        this.filtroPesquisa.tipoOrientacaoPosConsulta.length > 0
      ) {
        txtFilter += ` =tipoOrientacaoPosConsulta:"${this.filtroPesquisa.tipoOrientacaoPosConsulta
          .map(function (k) {
            return k.titulo;
          })
          .join(",")}"`;
        this.atendimentoFilterStorage.tipoOrientacaoPosConsulta =
          this.filtroPesquisa.tipoOrientacaoPosConsulta;
      }

      if (this.filtroPesquisa.status && this.filtroPesquisa.status.length > 0) {
        txtFilter += ` status=${this.filtroPesquisa.status
          .map(function (k) {
            return k.nome;
          })
          .join(",")}`;
        this.atendimentoFilterStorage.status = this.filtroPesquisa.status;
      } else {
        this.atendimentoFilterStorage.status = null;
      }

      if (this.filtroPesquisa.nomePaciente) {
        txtFilter += ` =paciente:"${this.filtroPesquisa.nomePaciente}"`;
        this.atendimentoFilterStorage.nomePaciente =
          this.filtroPesquisa.nomePaciente;
      } else {
        this.atendimentoFilterStorage.nomePaciente = null;
      }

      this.atendimentoFilterStorage.txtFilter = txtFilter;
      window.sessionStorage.setItem(
        `atendimentoFilterStorage_${this.$instanceId}`,
        JSON.stringify(this.atendimentoFilterStorage)
      );

      bus.$emit("clickSearchFilter", txtFilter);

      this.atualizarLista();
      this.dialogFiltroPesquisa = false;
    },
    setFilterQuery() {

      let txtFilter = ``;
      var obj = window.sessionStorage.getItem(`atendimentoFilterStorage_${this.$instanceId}`);
      this.atendimentoFilterStorage = {};

      if (obj) {
        this.atendimentoFilterStorage = Object.assign({}, JSON.parse(obj));
      } else {
        this.setarDatasPadrao();
      }

      if (this.atendimentoFilterStorage.start_date) {
        this.start_date = this.atendimentoFilterStorage.start_date;
        this.start_dateFormatada = moment(this.start_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      } else {
        if (this.start_date) {
          txtFilter += ` =dataInicial:${this.start_date}`;
        }
      }

      if (this.atendimentoFilterStorage.end_date) {
        this.end_date = this.atendimentoFilterStorage.end_date;
        this.end_dateFormatada = moment(this.end_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      } else {
        if (this.end_date) {
          txtFilter += ` =dataFinal:${this.end_date}`;
        }
      }

      this.filtroPesquisa.periodo = this.atendimentoFilterStorage.periodo;
      this.filtroPesquisa.grupoEmpresa =
        this.atendimentoFilterStorage.grupoEmpresa;
      this.filtroPesquisa.empresa = this.atendimentoFilterStorage.empresa;
      this.filtroPesquisa.unidade = this.atendimentoFilterStorage.unidade;
      this.filtroPesquisa.setor = this.atendimentoFilterStorage.setor;
      this.filtroPesquisa.medicoResponsavel =
        this.atendimentoFilterStorage.medicoResponsavel;
      this.filtroPesquisa.tipoConsulta =
        this.atendimentoFilterStorage.tipoConsulta;
      this.filtroPesquisa.tipoOrientacaoPosConsulta =
        this.atendimentoFilterStorage.tipoOrientacaoPosConsulta;
      this.filtroPesquisa.status = this.atendimentoFilterStorage.status;
      this.filtroPesquisa.nomePaciente =
        this.atendimentoFilterStorage.nomePaciente;

      if (this.atendimentoFilterStorage.txtFilter) {
        txtFilter = this.atendimentoFilterStorage.txtFilter;
      }

      bus.$emit("clickSearchFilter", txtFilter);

      this.atualizarLista();
      this.dialogFiltroPesquisa = false;
    },
    atualizarLista() {
      this.loading = true;
      var self = this;

      // Atualiza os botões statusFilterBottom
      this.updateStatusFilterBottom();

      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      var offset = page - 1;
      let order = sortDesc[0] ? "desc" : "asc";
      let sort = sortBy.length > 0 ? sortBy[0] : "id";

      let url = `/atendimento?`;

      if (itemsPerPage) {
        url += `max=${itemsPerPage}`;
      } else {
        url += `max=10`;
      }

      if (offset) {
        url += `&offset=${offset}`;
      }

      if (sort) {
        url += `&sort=${sort}&order=${order}`;
      }

      if (this.start_date) {
        url += `&dataInicial=${this.start_dateFormatada}`;
      }

      if (this.end_date) {
        url += `&dataFinal=${this.end_dateFormatada}`;
      }

      if (
        this.filtroPesquisa.grupoEmpresa &&
        this.filtroPesquisa.grupoEmpresa.id &&
        this.filtroPesquisa.grupoEmpresa.id > 0
      ) {
        url += `&grupoEmpresa=${this.filtroPesquisa.grupoEmpresa.id}`;
      }

      if (
        this.filtroPesquisa.empresa &&
        this.filtroPesquisa.empresa.id &&
        this.filtroPesquisa.empresa.id > 0
      ) {
        url += `&empresa=${this.filtroPesquisa.empresa.id}`;
      }

      if (
        this.filtroPesquisa.unidade &&
        this.filtroPesquisa.unidade.length > 0
      ) {
        url += `&unidade=${this.filtroPesquisa.unidade
          .map(function (k) {
            return k.id;
          })
          .join(",")}`;
      }

      if (this.filtroPesquisa.setor && this.filtroPesquisa.setor.length > 0) {
        url += `&setor=${this.filtroPesquisa.setor
          .map(function (k) {
            return k.id;
          })
          .join(",")}`;
      }

      if (
        this.filtroPesquisa.medicoResponsavel &&
        this.filtroPesquisa.medicoResponsavel.length > 0
      ) {
        url += `&medico=${this.filtroPesquisa.medicoResponsavel
          .map(function (k) {
            return k.id;
          })
          .join(",")}`;
      } else if (possuiPermissao("ROLE_AUXILIAR_ADMIN")) {
        url += "";
      } else if (
        !(
          (possuiPermissao("ROLE_ADMIN") &&
            !possuiPermissao("ROLE_AUXILIAR_ADMIN")) ||
          possuiPermissao("ROLE_CLIENTE_ADMIN")
        )
      ) {
        url += `&medico=${getPerfilId()}`;
      }

      if (
        this.filtroPesquisa.tipoConsulta &&
        this.filtroPesquisa.tipoConsulta.length > 0
      ) {
        url += `&tipoConsulta=${this.filtroPesquisa.tipoConsulta
          .map(function (k) {
            return k.id;
          })
          .join(",")}`;
      }

      if (this.filtroPesquisa.status && this.filtroPesquisa.status.length > 0) {
        url += `&status=${this.filtroPesquisa.status
          .map(function (k) {
            return k.id;
          })
          .join(",")}`;
      }
      
      if (this.filtroPesquisa.tipoOrientacaoPosConsulta) {
        url += `&tipoOrientacaoPosConsulta=${this.filtroPesquisa.tipoOrientacaoPosConsulta
          .map(function (k) {
            return k.id;
          })
          .join(",")}`;
      }

      if (this.filtroPesquisa.nomePaciente) {
        url += `&nomePaciente=${this.filtroPesquisa.nomePaciente}`;
      }

      this.$http
        .get(url)
        .then((response) => {
          this.items = response.data.content;
          this.total = response.data.totalSize;
          this.options.itemsPerPage = response.data.size;
          this.loading = false;
          bus.$emit("last-rest-call", self.getPreferenceParameter(url));
        })
        .catch((e) => {
          Promise.reject(
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: !e.response
                ? "Connection Refused!"
                : e.response.data.message,
            })
          );
        });
    },

    /**
     * Toggle selected items with the master checkbox.
     */
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.rows.slice();
      }
    },
    /**
     * Column shorting.
     *
     * @param column
     */
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    focusChangeNome(val) {
      if (val.target.value != "") {
        this.pacientes = Object.assign([{}], this.pacientes, [
          { nome: val.target.value, id: 99999 },
        ]);
        this.nomePacienteForm = { nome: val.target.value, id: 99999 };
      } else {
        this.nomePacienteForm = null;
      }

      this.filterNome({ nome: val.target.value, id: 99999 });
    },
    /**
     * Handler when user input something at the "Filter" text field.
     */
    filterSearch(val) {
      this.filters = Object.assign({}, this.filters, { search: val }); //this.$MultiFilters.updateFilters(this.filters, {search: val});
    },

    filterNome(val) {
      if (val && val.nome && val.nome != "") {
        this.items = Object.assign(
          [{}],
          this.items.filter((e) => {
            return e
              ? e.paciente.nome
                  .toLowerCase()
                  .indexOf(val.nome.trim().toLowerCase()) > -1
              : [{}];
          })
        ); //this.$MultiFilters.updateFilters(this.filters, {search: val});
      } else {
        this.items = Object.assign([{}], this.itemsUnmodified);
      }
    },

    filterCpf(val) {
      this.filters = Object.assign({}, this.filters, { cpf: val }); //this.$MultiFilters.updateFilters(this.filters, {search: val});
    },

    filterStatus(val) {
      this.filters = Object.assign({}, this.filters, { status: val }); //this.$MultiFilters.updateFilters(this.filters, {search: val});
    },

    /**
     * Handler when user  select some author at the "Author" select.
     */
    filterUnidade(val) {
      this.filters = Object.assign({}, this.filters, { unidade: val }); //this.$MultiFilters.updateFilters(this.filters, {added_by: val});
    },
    /**
     * Handler when select a date on "From" date picker.
     */
    filterStartDate(val) {
      // Close the date picker.
      this.$refs.show_start_date.save(val);
      //Convert the value to a timestamp before saving it.
      const timestamp = new Date(val + "T00:00:00Z").getTime();
      this.filters = Object.assign({}, this.filters, { start_date: timestamp }); //this.$MultiFilters.updateFilters(this.filters, {start_date: timestamp});
    },
    /**
     * Handler when select a date on "To" date picker.
     */
    filterEndDate(val) {
      // Close the date picker.
      this.$refs.show_end_date.save(val);
      //Convert the value to a timestamp before saving it.
      const timestamp = new Date(val + "T00:00:00Z").getTime();
      this.filters = Object.assign({}, this.filters, { end_date: timestamp }); //this.$MultiFilters.updateFilters(this.filters, {end_date: timestamp});
    },

    buscarUnidades() {
      var self = this;
      this.$http
        .get(`/unidade?max=-1`)
        .then((response) => {
          this.unidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar unidades",
          });
        });
    },

    intervalFormat(interval) {
      return interval.time;
    },

    buscarPacientes() {
      this.loadingPaciente = true;
      var self = this;
      this.$http
        .get(`/paciente?&max=50`)
        .then((response) => {
          this.pacientes = response.data.content;
          this.pacientesUnmodified = Object.assign([{}], this.pacientes);
          this.loadingPaciente = false;
        })
        .catch(() => {
          this.loadingPaciente = false;
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Pacientes",
          });
        });
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : "primary";
    },
    makeStatusObj(status) {
      return status == "PENDENTE"
        ? { id: 2, descricao: "PENDENTE" }
        : status == "CANCELADO"
        ? { id: 4, descricao: "CANCELADO" }
        : status == "CONFIRMADO"
        ? { id: 3, descricao: "CONFIRMADO" }
        : { id: 1, descricao: "AGENDADO" };
    },
    globalFilterClicked() {
      if (this.filtroPesquisa.periodo){
        this.setarDataCorrespondente(this.filtroPesquisa.periodo);
      }
      this.dialogFiltroPesquisa = true;
    },
    buscarGrupoEmpresas() {
      var self = this;
      this.$http
        .get(`/grupoEmpresa?max=-1&situacao=true`)
        .then((response) => {
          this.grupoEmpresas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Grupos de Empresas",
          });
        });
    },
    buscarEmpresas() {
      var self = this;
      this.$http
        .get(`/empresa?max=-1`)
        .then((response) => {
          this.empresas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Empresas",
          });
        });
    },
    buscarSetores() {
      var self = this;
      this.$http
        .get(`/setor?max=-1`)
        .then((response) => {
          this.setores = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar setores",
          });
        });
    },
    buscarMedicos() {
      var self = this;
      this.$http
        .get(`/perfil?authority=ROLE_MEDICO_ATENDIMENTO&max=-1`)
        .then((response) => {
          this.medicosResponsaveis = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos Radiologistas",
          });
        });
    },
    buscarTiposConsultas() {
      var self = this;
      this.$http
        .get(`/tipoConsulta?max=-1`)
        .then((response) => {
          this.tiposConsultas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Tipo Consultas",
          });
        });
    },
    buscarFavoritos() {
      var self = this;
      this.$http
        .get(`/favorito`)
        .then((response) => {
          this.favoritos = response.data.filter(f => f.situacao === true && f.tipo === "MZW2_ATENDIMENTO_LIST");
          this.favoritos.forEach(f => {
                f.active = "ml-1 mb-1 pr-4 elevation-2 teal white--text";
          });
          
          if (self.favoritoAtual){
            self.selecionarFavorito(self.favoritoAtual);
          }

        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Favoritos",
          });
        });
    },
    buscarTiposOrientacaoPosConsultas() {
      var self = this;
      this.$http
        .get(`/modeloPosConsulta?max=-1&situacao=true`)
        .then((response) => {
          this.tiposOrientacaoPosConsultas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Modelo Pos Consultas",
          });
        });
    },
    setarDatasPadrao() {
      this.start_dateFormatada = moment()
        .subtract(10, "days")
        .format("YYYY-MM-DD");
      this.start_date = moment(this.start_dateFormatada, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );

      this.end_dateFormatada = moment().add(10, "days").format("YYYY-MM-DD");
      this.end_date = moment(this.end_dateFormatada, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    },
    getPreferenceParameter(valor) {
      return {
        componentName: this.$options.name,
        value: valor,
        options: this.options,
      };
    },
    setTelaUserPreferences() {
      var userPreferenceStored = getUserPreferences(this.$options.name);

      if (userPreferenceStored && userPreferenceStored["options"]) {
        this.options = userPreferenceStored["options"];
      }
    },

    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    atualizarSelecionado(item, index, event) {
      this.selected = [];
      if (event == true) this.selected[0] = Object.assign({}, item);

      for (var x in this.selectedCheckBox) {
        if (x != index) this.selectedCheckBox[x] = null;
      }
    },
    fecharDialogFiltroPesquisa() {

      this.atendimentoFilterStorage = Object.assign(
        {},
        JSON.parse(window.sessionStorage.getItem(`atendimentoFilterStorage_${this.$instanceId}`))
      );
      this.dialogFiltroPesquisa = false;
      this.filtroPesquisa.periodo = this.atendimentoFilterStorage.periodo;
      this.filtroPesquisa.grupoEmpresa = Object.assign(
        {},
        this.atendimentoFilterStorage.grupoEmpresa
      );
      this.filtroPesquisa.empresa = Object.assign(
        {},
        this.atendimentoFilterStorage.empresa
      );
      this.filtroPesquisa.unidade = Object.assign(
        [],
        this.atendimentoFilterStorage.unidade
      );
      this.filtroPesquisa.setor = Object.assign(
        [],
        this.atendimentoFilterStorage.setor
      );
      this.filtroPesquisa.medicoResponsavel = Object.assign(
        [],
        this.atendimentoFilterStorage.medicoResponsavel
      );
      this.filtroPesquisa.tipoConsulta = Object.assign(
        [],
        this.atendimentoFilterStorage.tipoConsulta
      );
      this.filtroPesquisa.tipoOrientacaoPosConsulta = Object.assign(
        [],
        this.atendimentoFilterStorage.tipoOrientacaoPosConsulta
      );
      this.filtroPesquisa.status = Object.assign(
        [],
        this.atendimentoFilterStorage.status
      );
      this.filtroPesquisa.nomePaciente = Object.assign(
        [],
        this.atendimentoFilterStorage.nomePaciente
      );
    },
    isAtendimentoLastItemId(itemId) {
      return this.atendimentoLastItemId === itemId;
    },
    getRowClassItem (item) {
      let className = "row-class-item";

      // Retorna classe para atendimentos agendados.
      if(item.statusAtendimento.descricao == "AGENDADO") {
        className += " row-class-item-status-agendado"
      }
      
      // Retorna classe para atendimentos atendidos.
      if(item.statusAtendimento.descricao == "ATENDIDO") {
        className += " row-class-item-status-atendido"
      }
      
      // Retorna classe para atendimentos cancelados.
      if(item.statusAtendimento.descricao == "CANCELADO") {
        className += " row-class-item-status-cancelado"
      }

      // Retorna classe do último registro de atendimento visitado.
      if(this.isAtendimentoLastItemId(item.id)) {
        className += " row-class-item-last-visitor";
      }
      return className;
    },
    checkIfcloseIframeHistoriaClinica(event){
      if (typeof event.data.id  !== "undefined" && event.data.id === "FormularioView"){
        switch (event.data.acao) {
          case "close-formulario": this.closeDialogVisualizarHistoriaClinica(); break;
        }
      }
    }
  },

  filters: {
    /**
     * Format a timestamp into a d/m/yyy (because spanish format).
     *
     * @param value
     * @returns {string}
     */
    formatDate: function (value) {
      if (!value) return "";
      return new Date(value).toLocaleDateString("es-ES");
    },
    
  },

  created() {
    if (
      this.possuiPermissaoAtendente ||
      this.possuiPermissaoAdmin ||
      this.possuiPermissaoAuxiliarAdmin
    ) {
      this.headers.push({
        text: "Ação",
        align: "left",
        sortable: false,
        value: "acao",
      });
    }
    this.setUltimaTela({ tela: this.$route.fullPath });

    this.search = this.atendimentoQuery;
    // Carrega a variável a partir do atributo de estado do vuex (computed)
    this.refreshList = this.atendimentoRefreshList;

    this.setTelaUserPreferences();

    this.buscarUnidades();
    this.buscarEmpresas();
    this.buscarGrupoEmpresas();
    this.buscarSetores();
    this.buscarMedicos();
    this.buscarTiposConsultas();
    this.buscarTiposOrientacaoPosConsultas();
    this.buscarFavoritos();

    bus.$on("clickFilter", this.globalFilterClicked);
    bus.$on("limparClickFilter", this.limparClickFilter);
    this.$on("changeFavorite", this.changeFavorite);
  },
  beforeMount() {},
  beforeDestroy() {
    clearInterval(this.refreshInterval);
    bus.$emit("hideFilterComponent");
  },
  mounted() {
    var self_ = this;

    window.onmessage = (event) => {
        self_.checkIfcloseIframeHistoriaClinica(event);
    };

    bus.$emit("showFilterComponent");
  },
  destroyed() {
    bus.$emit("clickSearchFilter", "");
  },
  beforeRouteEnter(to, from, next) {
    
    var origemTelaDeEdicao =
      from.name && from.fullPath.indexOf("/atendimento/edit") > -1;
    if (!origemTelaDeEdicao) {
      clearTemporaryUserPreferences("atendimento-list");
    }
    
    next();
  },
};
</script>

<style scoped>
::v-deep .my-custom-dialog {
  align-self: flex-start;
  margin-top: 65px;
}
.short {
  width: 100px;
}
.short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.brc {
  align-items: center;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 0 1px rgb(100 121 143 / 30%);
  box-shadow: inset 0 0 0 1px rgb(100 121 143 / 30%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #5f6368;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 20px;
  padding: 0 12px;
  margin-right: 8px;
  max-width: 170px;
  min-width: 64px;
}
.bzB {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brf {
  height: 16px;
  margin-right: 8px;
  width: 16px;
  -webkit-box-flex: 0 0 16px;
  -webkit-flex: 0 0 16px;
  flex: 0 0 16px;
}
.brg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 0.675rem;
  letter-spacing: 0.2px;
}
._nowarp {
  white-space: nowrap;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.spin-anexos {
  width: 140px;
}
.mytable .v-table tbody tr:not(:last-child) {
  border-bottom: none;
  background-color: lightgoldenrodyellow;
}
.selectedRow {
  outline-color: red;
}
.primary {
  /** avoid using !important, added just for example**/
  background-color: rgba(8, 158, 96, 0.11);
}

.as-console-wrapper {
  display: none !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: green !important;
}
.selected {
  background-color: #90ee90;
}

/* CSS para controle da exibição dos itens do v-data-table com id:  atendimento-table-item */
::v-deep #atendimento-table-item .row-class-item {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
::v-deep #atendimento-table-item .row-class-item:hover {
  box-shadow: 
    inset 1px 0px 0px #dadce0, 
    inset -1px 0px 0px #dadce0, 
    0px 1px 2px 0px rgba(60, 64, 67, 30%), 
    0px 1px 3px 1px rgba(60, 64, 67, 15%) !important;
  z-index: 2;
  background-color: inherit;
}
::v-deep .theme--dark #atendimento-table-item .row-class-item:hover {
  box-shadow: 
    inset 1px 0px 0px rgb(255, 255, 255, 20%), 
    inset -1px 0px 0px rgba(255, 255, 255, 20%), 
    0px 0px 4px 0px rgba(95, 99, 104, 60%), 
    0px 0px 6px 2px rgba(95, 99, 104, 60%) !important;
  z-index: 2;
  background-color: inherit;
}
::v-deep #atendimento-table-item .row-class-item a:hover {
  text-decoration: underline;
}
::v-deep #atendimento-table-item .row-class-item .mdi-checkbox-blank-outline {
  opacity: 0.3;
}
::v-deep #atendimento-table-item .row-class-item:hover .mdi-checkbox-blank-outline {
  opacity: 1;
}
::v-deep #atendimento-table-item .row-class-item .icon-historia-clinica {
  opacity: 0.3;
}
::v-deep #atendimento-table-item .row-class-item:hover .icon-historia-clinica {
  opacity: 1;
}
::v-deep #atendimento-table-item .row-class-item #item-actions-botton {
  opacity: 0.3;
}
::v-deep #atendimento-table-item .row-class-item:hover #item-actions-botton {
  opacity: 1;
}
::v-deep #atendimento-table-item .row-class-item-status-agendado {
  font-weight: bold;
}
::v-deep #atendimento-table-item .row-class-item-status-atendido {
  background-color: rgba(242, 245, 245, 0.8) !important;
}
::v-deep .theme--dark #atendimento-table-item .row-class-item-status-atendido {
  background-color: rgba(242, 245, 245, 0.2) !important;
}
::v-deep #atendimento-table-item .row-class-item-status-cancelado {
  font-weight: 300;
}
::v-deep #atendimento-table-item .row-class-item-last-visitor {
  box-shadow: inset 0px 0px 0px 2px rgba(112, 191, 115, 0.80);
}

</style>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0!important;
}

</style>
