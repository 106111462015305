<template>
  <v-container fluid>
    <v-card flat>
      <v-form
        lazy-validation
        v-on:submit.prevent="onSubmit"
        enctype="multipart/form-data"
        ref="form"
      >
        <v-card-title>
          <v-row>
            <v-col md="11" cols="12">
              <span class="headline primary--text">{{ titulo }}</span>
            </v-col>
            <v-col md="1" cols="2" align="right">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    right
                    outlined
                    class="mr-2"
                    ripple
                    color="primary"
                    icon="icon"
                    @click="posConsultaExit"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col md="6" cols="12">
                <v-card-title soft>Título</v-card-title>
                <v-autocomplete
                  v-model="registroPosConsulta.modeloPosConsulta"  
                  filled
                  rounded
                  clearable
                  label="Modelo Pós Consulta"
                  item-text="titulo"
                  item-value="id"
                  return-object  
                  @change="onChangeModelo"        
                  :items="modelosPosConsulta"       
                  :rules="rules.campoObrigatorio" 
                ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12">
              <Editor
                :titleEditor="true"
                :title="tituloConteudoPosConsulta"
                v-model="registroPosConsulta.conteudo"
              ></Editor>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pr-4">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="posConsultaExit"> Voltar </v-btn>
          <v-btn v-show="!inativo" v-if="hasPermissaoFinalizarAtendimento" type="submit" color="primary"> Finalizar Pós Consulta </v-btn>
        </v-card-actions>

        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import Editor from "@/components/comum/Editor";
import { mapActions, mapGetters } from "vuex";
import { possuiPermissao } from "../../seguranca";

export default {
  name: "registroPosConsulta-create-edit",
  props: {
    isModal: { // verifica se o componente foi solicitado via outro componente ou se foi via Rota
      type: Boolean,
      default: false
    },
    idParameter:  { 
      type: String,
      default: null
    },
    nomeTipoConsulta:  { 
      type: String,
      default: null
    },
    inativo:  { 
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tituloConteudoPosConsulta: "Relatório de Pós Consulta",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["paciente.nome"],
        sortDesc: [false],
      },
      loading: true,
      modelosPosConsulta: [],
      registroPosConsulta: {},
      errors: [],
      titulo: "Orientação Pós Consulta",
      rules: {
        campoObrigatorio: [(v) => !!v || "Campo é obrigatório"],
        textoObrigatorio: [
          (v) => !!v || "Campo é obrigatório",
          (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        ],
        emailObrigatorio: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        ],
        selecaoObrigatoria: [(v) => v.length > 0 || "Seleção Obrigatória"],
      },
      dialog: false,
    };
  },
  components: { Alerta, Editor },
  computed: {
    ...mapGetters(["ultimaTela"]),
    hasPermissaoFinalizarAtendimento(){
      return possuiPermissao(['ROLE_MEDICO_RADIOLOGISTA','ROLE_MEDICO_ATENDIMENTO'])
    },
  },
  watch: {},
  methods: {
    ...mapActions(["setAgendamentoQuery", "setAlerta"]),
    loadModel() {
      var self = this;

      // let parametroId = this.$route.params.id ? this.$route.params.id: this.idParameter
      let parametroId;
      if(this.isModal) {
        parametroId = parametroId = this.idParameter ? this.idParameter : null;
      } else {
        parametroId = this.$route.params.id ? this.$route.params.id: null;
      }

      if (parametroId) {
        var url = `/registroPosConsulta/${parametroId}`;
        this.$http
          .get(`${url}`)
          .then((response) => {
            self.registroPosConsulta = response.data;

            self.buscarModeloPosConsulta();

          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao carregar Atendimento",
            });
          });
      }else{
          self.registroPosConsulta = {};
          self.buscarModeloPosConsulta();
      }
    },
    async onSubmit() {
      var self = this;

      if (!this.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor preencher todos os campos",
        });
        return
      } 

      if(!this.registroPosConsulta.conteudo) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor informar um conteúdo válido para Registro de Orientação Pós Consulta",
        });
        return
      }

      if (this.registroPosConsulta.id) {
        this.$http
          .put("/registroPosConsulta", self.registroPosConsulta)
          .then((registroPosConsultaUpdated) => {

            if (this.isModal){
              this.$emit('updated', registroPosConsultaUpdated)
              self.registroPosConsulta = {};
            }else{
              self.cancelar();
            }

            self.setAlerta({
              tipoAlerta: "success",
              textAlerta: "Pós Consulta atualizada com sucesso",
            });
          })
          .catch((exceptionError) => {
            this.$emit('error', exceptionError)
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao atualizar Pós Consulta",
            });
          });
      } else {
        this.$http
          .post("/registroPosConsulta", self.registroPosConsulta)
          .then((registroPosConsultaSaved) => {
            
            if (this.isModal){
              this.$emit('saved', registroPosConsultaSaved)
              self.registroPosConsulta = {};
            }else{
              self.cancelar();
            }
            self.setAlerta({
              tipoAlerta: "success",
              textAlerta: "Pós Consulta criada com sucesso",
            });
          })
          .catch((exceptionError) => {
            this.$emit('error', exceptionError)
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao criar Pós Consulta",
            });
          });
      }
    },
    onChangeModelo() {
      if(this.registroPosConsulta.modeloPosConsulta) {
        this.registroPosConsulta.titulo = this.registroPosConsulta.modeloPosConsulta.titulo;
        this.registroPosConsulta.conteudo = this.registroPosConsulta.modeloPosConsulta.conteudo;
      } else {
        this.registroPosConsulta.titulo = "";
        this.registroPosConsulta.conteudo = "";
      }
    },
    buscarModeloPosConsulta() {
      var self = this;
      this.$http
        .get(`/modeloPosConsulta?situacao=true&max=-1&nomeTipoConsulta=${self.nomeTipoConsulta}`)
        .then((response) => {
          self.modelosPosConsulta = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Modelos de Orientação Pós Consulta",
          });
        });
    },
    posConsultaExit() {
      this.$emit('exit')
    },
    cancelar() {
      if (this.ultimaTela) {
        this.$router.push({ path: this.ultimaTela });
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {
    //this.buscarModeloPosConsulta();
    this.loadModel();
  },
  mounted(){
    //this.buscarModeloPosConsulta();
    this.loadModel();
  }
};
</script>

